import { useState, useCallback, useMemo } from "react"
import { useListingFilters } from "@app/hooks/useListingFilters"
import { ELLIPSIS } from "@app/hooks/usePagination"

import type { Option } from "@components/Select"

type sortOption = {
  label: string
  value: string
}

export type useListingFilteringLogicArgs = {
  itemsPerPage: number
  sortOptions: sortOption[]
  initialItems: any[]
  totalItems: number
}

const useListingFilteringLogic = ({ itemsPerPage, sortOptions, initialItems, totalItems }: useListingFilteringLogicArgs) => {
  const [activeSortOption, setActiveSortOption] = useState(sortOptions[0])
  const [humanCurrentPageNumber, setHumanCurrentPageNumber] = useState(1)

  const {
    searchTerm,
    setActiveSearchTerm,
    filterItemsBySortOption,
    submittedSearchTerm,
    setSubmittedSearchTerm,
    filterItemsByPagination,
    selectedTag,
    setSelectedTag,
  } = useListingFilters()

  const handleSortChange = useCallback((option: Option) => setActiveSortOption(option), [])

  const handleSearchChange = useCallback(
    e => {
      setActiveSearchTerm(e?.target?.value)
    },
    [setActiveSearchTerm]
  )

  const handleSearchSubmit = useCallback(
    e => {
      e?.preventDefault()
      setSubmittedSearchTerm(searchTerm)
    },
    [setSubmittedSearchTerm, searchTerm]
  )

  const { itemsFilteredBySortOption } = useMemo(
    () => filterItemsBySortOption({ items: initialItems, activeSortOption }),
    [activeSortOption, filterItemsBySortOption, initialItems]
  )

  // pagination filtering must comes before sort filtering
  const { itemsFilteredByPagination } = useMemo(
    () =>
      filterItemsByPagination({
        items: itemsFilteredBySortOption,
        humanCurrentPageNumber,
        itemsPerPage,
      }),
    [filterItemsByPagination, humanCurrentPageNumber, itemsFilteredBySortOption, itemsPerPage]
  )

  const handleTagClick = useCallback(
    e => {
      setSelectedTag(e?.target?.textContent || e)
    },
    [setSelectedTag]
  )

  const handlePageChange = useCallback(num => num !== ELLIPSIS && setHumanCurrentPageNumber(num), [])
  const handleNextPage = useCallback(() => setHumanCurrentPageNumber(humanCurrentPageNumber + 1), [humanCurrentPageNumber])
  const handlePreviousPage = useCallback(() => setHumanCurrentPageNumber(humanCurrentPageNumber - 1), [humanCurrentPageNumber])

  const totalPages = useMemo(() => {
    return Math.ceil(totalItems / itemsPerPage)
  }, [totalItems, itemsPerPage])

  const createPaginationOptions = useCallback(() => {
    return {
      totalPages,
      humanCurrentPageNumber,
      handlePageChange,
      handleNextPage,
      handlePreviousPage,
      hasPreviousPage: humanCurrentPageNumber !== 1,
      hasNextPage: humanCurrentPageNumber !== totalPages,
    }
  }, [humanCurrentPageNumber, handleNextPage, handlePageChange, handlePreviousPage, totalPages])

  const paginationOptions = useMemo(() => createPaginationOptions(), [createPaginationOptions])

  const FILTERED_ITEMS = itemsFilteredByPagination

  return {
    filteredItems: FILTERED_ITEMS,
    hasItems: !!FILTERED_ITEMS?.length,
    searchTerm,
    handleSearchChange,
    activeSortOption,
    handleSortChange,
    handleSearchSubmit,
    paginationOptions,
    sortOptions,
    submittedSearchTerm,
    selectedTag,
    handleTagClick,
  }
}

export { useListingFilteringLogic }
