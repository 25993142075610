import { SORT_OPTION_VALUES } from "@app/hooks/useListingFilters"
import { useEffect, useMemo, useCallback } from "react"
import { useListingFilteringLogic } from "./useListingFilteringLogic"
import { useSanity } from "./useSanity"
import { DEFAULT_RESET_LABEL } from "@components/SelectableTags"

type useListingBrandsArgs = {
  brands: any
}

const sortOptions = [
  { label: "A-Z", value: SORT_OPTION_VALUES.A_TO_Z },
  { label: "Z-A", value: SORT_OPTION_VALUES.Z_TO_A },
]

const ALPHABET = Array.from("abcdefghijklmnopqrstuvwxyz")
const headerTags = ["#", ...ALPHABET]

const ITEMS_PER_PAGE = 24

const projection = [
  `{
    image,
    _id,
    title,
    shopify {
      handle
    },
 }`,
]

const useListingBrands = ({ brands }: useListingBrandsArgs) => {
  const brandItems = useMemo(() => brands?.edges?.map(({ node }: { node: any }) => node), [brands?.edges])

  const { getSanity, sanityItems, loading } = useSanity()

  const {
    searchTerm,
    handleSearchChange,
    activeSortOption,
    handleSortChange,
    paginationOptions,
    submittedSearchTerm,
    selectedTag,
    handleTagClick,
    handleSearchSubmit,
  } = useListingFilteringLogic({
    itemsPerPage: ITEMS_PER_PAGE,
    sortOptions,
    initialItems: sanityItems?.paginated?.length ? sanityItems?.paginated : brandItems,
    totalItems: sanityItems?.all,
  })

  useEffect(() => {
    const searchTerm = submittedSearchTerm ? `&& [title, description] match "*${submittedSearchTerm}*"` : ""

    let filters = ""
    if (selectedTag && selectedTag !== DEFAULT_RESET_LABEL) {
      if (selectedTag === "#") {
        // workaround for limitations of groq
        filters = ALPHABET.map(char => `&& !(string::startsWith(lower(title), "${char}"))`).join("")
      } else {
        filters = `&& string::startsWith(lower(title), "${selectedTag}")`
      }
    }

    const baseQuery = [
      `*[_type == "collection" && isBrand == true && shopify.published == true && !(_id in path('drafts.**')) ${searchTerm} ${filters}]`,
    ]

    const pagination = `[
      ${(paginationOptions?.humanCurrentPageNumber - 1) * ITEMS_PER_PAGE}...${paginationOptions?.humanCurrentPageNumber * ITEMS_PER_PAGE}
    ]`

    const sorts = [`| order(${activeSortOption?.value})`]

    const paginatedQuery = baseQuery.concat(sorts).concat(pagination).concat(projection).join("")

    const allQuery = `count(${baseQuery})`

    const query = `{ 'paginated': ${paginatedQuery}, 'all': ${allQuery} }`

    getSanity(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedSearchTerm, activeSortOption, paginationOptions?.humanCurrentPageNumber, selectedTag])

  const handleLetterClick = useCallback(e => handleTagClick(e?.target?.dataset?.value || e?.target?.textContent), [handleTagClick])

  return {
    hasItems: !!sanityItems?.paginated?.length,
    filteredItems: sanityItems?.paginated,
    searchTerm,
    handleSearchChange,
    sortOptions,
    activeSortOption,
    handleSortChange,
    paginationOptions,
    headerTags,
    handleTagClick: handleLetterClick,
    selectedTag,
    handleSearchSubmit,
    loading,
    itemsPerPage: ITEMS_PER_PAGE,
  }
}

export { useListingBrands }
