import { memo } from "react"
import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputRightElement, InputGroup, Box } from "@chakra-ui/react"

import type { ChangeEvent } from "react"

type SearchBarProps = {
  handleSubmit?: () => void
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  bgColor?: string
  value?: string
  fontSize?: any
}

const SearchBar: React.FC<SearchBarProps> = ({ handleSubmit, handleChange, placeholder, bgColor, value, fontSize = "md" }) => {
  return (
    <Box
      as="form"
      // @ts-expect-error
      onSubmit={event => {
        event?.preventDefault()
        handleSubmit?.()
      }}
    >
      <InputGroup>
        <Input
          placeholder={placeholder}
          variant="search"
          type="text"
          aria-label="Open search"
          pr={15}
          textOverflow="ellipsis"
          onChange={handleChange}
          bg={bgColor}
          value={value}
          h={[10, 10, 12]}
          fontSize={fontSize}
        />
        <InputRightElement
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="full"
          mr={7}
          cursor="pointer"
          onClick={event => {
            event?.preventDefault()
            handleSubmit?.()
          }}
        >
          <SearchIcon w={4} h={4} />
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

const MemoSearchBar = memo(SearchBar)
export { MemoSearchBar as SearchBar }
