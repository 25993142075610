import { memo, useMemo } from "react"
import { Listing } from "@app/components/Listing/Listing"
import { Box } from "@chakra-ui/react"
import { SearchBar } from "@components/Search/SearchBar"
import { SelectableTags } from "../SelectableTags"
import { BrandItem } from "./BrandItem"
import { useListingBrands } from "@app/hooks/useListingBrands"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/brands"
import type { PageProps } from "@root/types/global"

const BrandListGridChildren = ({ filteredItems }: any) => filteredItems?.map(item => <BrandItem key={item?._id} {...item} />)

const BrandListing: React.FC<PageProps<Props>> = ({ page, brands }) => {
  const {
    searchTerm,
    handleSearchChange,
    hasItems,
    filteredItems,
    sortOptions,
    activeSortOption,
    handleSortChange,
    paginationOptions,
    headerTags,
    handleTagClick,
    selectedTag,
    handleSearchSubmit,
    loading,
    itemsPerPage,
  } = useListingBrands({
    brands,
  })

  const headerChildren = useMemo(
    () => (
      <>
        <Box mt={[2, 7]}>
          <SearchBar
            placeholder={page?.additionalSearchBarPlaceholder}
            bgColor="brand.offWhite"
            handleChange={handleSearchChange}
            handleSubmit={handleSearchSubmit}
            value={searchTerm}
          />
          <Box mt={[4, 4, 8]}>
            <SelectableTags
              tags={headerTags}
              handleClick={handleTagClick}
              selectedTag={selectedTag}
              minW={{ base: 12, lg: "unset" }}
              upperCaseTags
              variant="brand"
            />
          </Box>
        </Box>
      </>
    ),
    [handleSearchChange, handleSearchSubmit, searchTerm, selectedTag, handleTagClick, headerTags, page?.additionalSearchBarPlaceholder]
  )

  return (
    <Listing
      headerProps={{
        title: page?.title,
        children: headerChildren,
        paddingTop: [12, 18],
        paddingBottom: [8, 7],
      }}
      gridProps={{
        hasItems,
        children: <BrandListGridChildren filteredItems={filteredItems} />,
        columns: [1, 2, 3, 4],
        columnGap: [0, 4, 4],
        rowGap: [14, 14, 16],
        noItemsLabel: page?.additionalNoBrandsLabel,
        itemsPerPage,
      }}
      paginationProps={{
        computedOptions: paginationOptions,
      }}
      sortOptions={sortOptions}
      handleSortChange={handleSortChange}
      activeSortOption={activeSortOption}
      loading={loading}
    />
  )
}

const BrandList: React.FC<PageProps<Props>> = ({ page, brands }) => {
  return (
    <>
      <BrandListing page={page} brands={brands} />
      {!!page?.sections?.length && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(BrandList)
