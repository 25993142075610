import { graphql, PageProps } from "gatsby"
import Page from "@components/Brand/BrandList"

export type Props = PageProps<GatsbyTypes.PageBrandsQuery, GatsbyTypes.PageBrandsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageBrands {
    page: sanityPageBrands {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 8 })
      additionalSearchBarPlaceholder
      additionalNoBrandsLabel
      _type
    }
    brands: allSanityCollection(
      filter: { isBrand: { eq: true }, shopify: { published: { eq: true } } }
      sort: { fields: title }
      limit: 24
    ) {
      edges {
        node {
          ...SanityCollectionFragment
        }
      }
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
