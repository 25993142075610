import { memo } from "react"
import { Image, GridItem, Box, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { useConfigContext } from "@app/providers/config"

type BrandItemProps = {
  title?: string
  shopify?: any
  image?: string
}

const BrandItem: React.FC<BrandItemProps> = ({ title, shopify, image }) => {
  const {
    settings: { routes },
  } = useConfigContext()

  const brandCollectionUrl = `${routes.COLLECTION}/${shopify?.handle}`

  return (
    <GridItem>
      <LinkBox display="flex" flexDirection="row" flexWrap="wrap" height="100%">
        <Box w="full" h={60} display="flex" flexGrow={1} alignItems="center" justifyContent="center" overflow="hidden">
          {image && <Image src={image} alt={`${title} logo`} bg="white" />}
        </Box>
        <Heading as="p" size="h4" mb={4} mt={2} w="full">
          {title}
        </Heading>
        <LinkOverlay as={CustomLink} to={brandCollectionUrl} title="View Products" variant="underline" styles={{ mt: "auto", w: "full" }}>
          {/* TODO: add below label to brands page additionals */}
          <Text fontSize="sm">View Products</Text>
        </LinkOverlay>
      </LinkBox>
    </GridItem>
  )
}

const MemoBrandItem = memo(BrandItem)
export { MemoBrandItem as BrandItem }
